import axios from '@/http/index'

// 获取当前登录状态 0 是正常 -1 没登录
// export const checkIsLogin = (params) => {
//     return axios({
//         method: 'GET',
//         url: '/login/getStatus',
// 		params
//     })
// }

// 获取token
export const getLoginStatus = (params) => {
    return axios({
        method: 'GET',
        url: 'https://user.tsgyun.com/user/get-login-status',
		params
    })
}

// 通过token获取用户信息
export const getUserInfo = (params) => {
    return axios({
        method: 'GET',
        url: '/login/get-current-user',
		params
    })
}

// 用户退出接口
export const userLogout = (params) => {
    return axios({
        method: 'GET',
        url: '/login/loginout',
		params
    })
}

// 用户退出接口2
export const userLogout2 = (params) => {
    return axios({
        method: 'POST',
        url: 'https://user.tsgyun.com/user/logout',
		params
    })
}


// 更新用户头像
export const upCurrentUserImg = (data) => {
    return axios({
        method: 'post',
        url: '/reg/up-cur-user-img',
		data
    })
}

// banner信息获取
export const getBanner = (params) => {
    return axios({
        method: 'GET',
        url: '/index/index-banner',
		params
    })
}

// 获取论文复现列表
export const getIndexArt = (params) => {
    return axios({
        method: 'GET',
        url: '/index/index-art',
        params
    })
}


// 获取科研工具列表
export const getIndexTools = (params) => {
    return axios({
        method: 'GET',
        url: '/index/index-tools',
        params
    })
}

// 课程列表
export const getIndexOnlineLesson = (params) => {
    return axios({
        method: 'GET',
        url: '/index/index-online-lesson',
        params
    })
}

// 获取全部课程类型
export const getAllLessonTags = () => {
    return axios({
        method: 'GET',
        url: '/index/get-all-lesson-tags',
    })
}

// 获取课程详情
export const getLessionDetail = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/get-lession-detail',
        params
    })
}


// 获取课程下面的列表
export const getLessionList = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/get-lesson-list',
        params
    })
}

// 获取全部常见问题
export const getCommonQa = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/get-common-qa',
        params
    })
}

// 获取课程的课程回答和讨论
export const getlessonQa = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/getlesson-qa',
        params
    })
}

// 判断当前课程可以反馈 true 是可以
export const checkStatus = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/get-lessonback-status',
        params
    })
}

// 学生学习课程记录
export const addStuV = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/add-stu-lesson-v',
        params
    })
}

// 添加课程提问/讨论
export const addQa = (data) => {
    return axios({
        method: 'POST',
        url: '/fles/add-lesson-qa',
        data
    })
}

// 添加我的评价反馈
export const addMyBack = (data) => {
    return axios({
        method: 'POST',
        url: '/fles/add-my-back',
        data
    })
}


// 全部的新增点赞通用地址
export const lessonUpDown = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/lesson-up-down',
        params
    })
}

// 回复讨论
export const addTalkQa = (data) => {
    return axios({
        method: 'POST',
        url: '/fles/add-talk-qa',
        data
    })
}



// 添加我的意向课程
export const addMyStuLesson = (data) => {
    return axios({
        method: 'POST',
        url: '/fles/add-my-stu-lesson',
        data
    })
}

// 加入学习
export const addLesson = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/add-join',
        params
    })
}

// 课程相关工具
export const getlkygj = (params) => {
    return axios({
        method: 'GET',
        url: '/fles/getlkygj',
        params
    })
}

// 我要讲课
// 获取当前用户talk信息
export const getCurUserTalk = (params) => {
    return axios({
        method: 'GET',
        url: '/reg/get-cur-user-talk',
        params
    })
}

//更新当前用户信息
export const upCurUserTalk = (data) => {
    return axios({
        method: 'post',
        url: '/reg/up-cur-user-talk',
        data
    })
}

// 发送验证码
export const sendCode = (params) => {
    return axios({
        method: 'post',
        url: '/reg/send_code',
        params
    })
}

// 前台提交talk申请
export const submitTalk = (data) => {
    return axios({
        method: 'post',
        url: '/reg/reg-talk',
        data
    })
}

// 获取提交的讲课记录
export const getTalkList = (params) => {
    return axios({
        method: 'get',
        url: '/reg/get-reg-self-info-list-talk',
        params
    })
}
// 手机端：获取提交的讲课记录
export const getTalkListMobils = (params) => {
    return axios({
        method: 'get',
        url: '/reg/get-reg-self-info-list-talk-phone',
        params
    })
}
// 讲课记录撤回
export const delTalkList = (params) => {
    return axios({
        method: 'get',
        url: '/reg/del-reg-self-info-talk',
        params
    })
}

//  统计(首页点击统计)
export const getNumStatis = (params) => {
    return axios({
        method: 'post',
        url: '/statistic/add-user-use',
        params
    })
}

// 科研工具 11 单独跳转的地址
export const getToolBib = (params) => {
    return axios({
        method: 'get',
        url: 'https://qfxcte.qfxuexi.com/ftools/get-bib',
        params
    })
}
// 问题反馈
export const addPlatBack = (data) => {
    return axios({
        method: 'post',
        url: '/fles/add-plat-back',
        data
    })
}
