import axios from 'axios'
import Vue from 'vue'
import Store from '@/store';
import Router from '@/router';
import { Loading, MessageBox } from 'element-ui'

//创建axios
const service = axios.create({
	baseURL: process.env.NODE_ENV === "development" ?  '/api' : process.env.VUE_APP_BASE_URL,
	timeout: 60000,
	withCredentials: true
})

let getTokenCount = 0;
let loadingCount = 0;    //打开 laoding  的个数
let loading;
let showMessageCount = 0;
let notGetToken = ['https://user.tsgyun.com/user/get-login-status','/reg/send_code', '/reg/up-cur-user-talk', '/reg/reg-talk', '/reg/get-reg-self-info-list-talk-phone', '/reg/del-reg-self-info-talk']
// loading开始 方法
function startLoading() {  
     loadingCount++;
     loading = Loading.service({ 
         lock: true,
         text: '拼命加载中...',
         spinner: 'el-icon-loading',  // 自定义图标
         background: 'rgba(0, 0, 0, 0.7)'
     })
 }
 
 // loading结束 方法
function loadingClose() {  
     loadingCount--;
     loading.close()
 }

// 请求拦截器
service.interceptors.request.use(config => {
	config.headers['Content-Type'] = 'application/json;charset=utf-8'
	config.data = JSON.stringify(config.data)
	
	const token = localStorage.getItem('token');
	if (token) {
		config.headers.Token = token;
	} else {
		if(config.url == 'https://user.tsgyun.com/user/get-login-status') {
			getTokenCount++
		}
		if (getTokenCount == 0 && notGetToken.indexOf(config.url) == -1) {
			getTokenCount++
			Store.dispatch('getToken')
		} 
	}
	
	if (config.requestLoading) {
		startLoading();
	}
	
	return config
}, error => {
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use((res) => {
	// console.log(res );
	if (res.config.requestLoading) {
	    loadingClose();
	}
	// 未登录
	if (res.data.code == 1 || res.data.code == 4) {
		Store.commit('setUserInfo', null)
		localStorage.removeItem('token')
		if(res.config.url == '/reg/get-cur-user-talk') {
			MessageBox('您未进行登录或登录信息已过期, 去登录?', '提示', {
			    type: 'warning'
			}).then(() => {
			    window.open('https://user.tsgyun.com/user/ssologin?sid=605&returnUrl=' + encodeURIComponent(
			        window.location.href), '_self')
			}).catch(() => {});
		} else if(showMessageCount == 0) {
			Vue.$message.error('您未进行登录或登录信息已过期')
			showMessageCount++
		}
		
		setTimeout(() => {
			showMessageCount = 0
		}, 3000)
		
		return Promise.reject(res)
	}
	// 无权限
	if (res.data.code == 2) {
		Vue.$message.error(res.data.msg)
		return Promise.reject(res)
	}
	
	if (res.data.code !== 0) {
		Vue.$message.error(res.data.msg)
		return Promise.reject(res)
	}
	
	return res.data
}, (error) => {
	if (error.config.requestLoading) {
	    loadingClose();
	}
	Vue.$message.error(error.toString())
	return Promise.reject(error)
})

export default service