import Vue from 'vue'
import Vuex from 'vuex'
import { getLoginStatus } from '@/http/api/index'

Vue.use(Vuex)

export default new Vuex.Store({
    // 数据源 使用：this.$store.state.xxx
    state: {
       userInfo: {},
	   screenData: {},  //设备数据
    },
    // 派生数据 使用：this.$store.getters.xxx
    getters: {
		
    },
    // 更改数据-同步 使用：this.$store.commit('xxx', data)
    mutations: {
       setUserInfo(state, data) {
		   state.userInfo = data
	   },
	   setScreenData(state, data) {
	   	 state.screenData = data
	   },
        
    },
    actions: {
    	getToken(context, data) {
    		return new Promise((resolve, reject) => {
				getLoginStatus().then(res => {
					if(res.data.token) {
						localStorage.setItem('token', res.data.token)
						if(!data) router.go(0)
						resolve(res.data.token)
					} else {
						localStorage.removeItem('token')
						reject()
					}
				}).catch(error => {
					reject()
				})
    		  })
    	},
    }
})